
import { defineComponent, onMounted, reactive, ref } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import WaystarForm from "@/modules/waystarForm/WaystarFormComponent.vue";
import { getBatch, getExistingBatch } from "@/api/batch.api";
import { addBatch } from "@/api/batch.api";
import { useRouter } from "vue-router";

export interface Data {
  batch: any;
  activeBatchItem: any;
  isReadonly: boolean;
}

export default defineComponent({
  name: "ClaimsBatchPage",
  components: { WaystarForm },
  props: ["formType", "claims", "batchId"],
  setup(props) {
    const router = useRouter();
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      batch: undefined,
      activeBatchItem: undefined,
      isReadonly: false,
    });
    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Claims Batch");

      if (props.batchId) {
        data.batch = await getExistingBatch(props.batchId);
        data.isReadonly = true;
      } else {
        data.batch = await getBatch({
          claims: props.claims,
          formType: props.formType,
        });
      }
    });

    function selectItem(item) {
      data.activeBatchItem = item;
    }

    async function submit() {
      await addBatch({ Batch: data.batch, BatchType: props.formType });
      router.push({
        name: "batchQueuePage",
      });
    }

    return {
      organizationId,
      selectItem,
      submit,
      data,
    };
  },
});
